<template>

        <div>

            <b-modal  
      @shown="onShown()"
     :no-close-on-esc="true"
     :no-close-on-backdrop="true"
      :hide-header="true"
      :hide-footer="true"
      content-class="modal-blue"
      id="change-pre-reg-subject"
      size="xl"
      scrollable
      centered>
        <!-- Modal content-->
       
       
        <div class="row ">
          <div class="col mb-5 ">
                    
                    <div v-for="(modules,i) of Object.keys(theData)">
                    <h3 >{{modules}}</h3>
                    <table class="table table-responsive-lg">
                        
                        <tr v-for="(subjects,j) of theData[modules]" style="cursor:pointer"  @click="changeToSubject(subjects)">

                                <td> {{subjects.subject_code}} | {{ helpers.getLocalizedField(subjects, 'subject')}}</td>

                        </tr>
                        
                    </table>


                    </div>    

          </div>
        </div>






        <div class="row">
          <div class="col mt-0 pt-3 mt-sm-5 pt-sm-0 mb-3">
            <button type="button" class="btn btn-light-bold" @click="hideModal()">{{ $t('registration.close') }}</button>
          </div>
        </div>
    </b-modal>


        </div>



</template>
<script>
    export default{

            data(){

                return {

                        theData:[]


                }

            },


            methods:{

                onShown(){

                        this.$store.dispatch('registration/getProgramSubjects').then(response=>{

                                

                                this.theData=response.data
                        })

                },
                hideModal() {
                this.$bvModal.hide('change-pre-reg-subject');
                     this.$emit('closed');

                },

                 
                changeToSubject(subjects){

                    this.$store.dispatch('registration/setChangedPreRegSubject',subjects)
                    this.$bvModal.hide('change-pre-reg-subject');
                    this.$bvModal.hide('groups-modal');
                    

                }
            }

    }

</script>
