<template>
  <div>
    <b-modal  
    :no-close-on-esc="true"
     :no-close-on-backdrop="true"
      @shown="onShown()"
      :hide-header="true"
      :hide-footer="true"
      content-class="modal-blue"
      id="groups-modal"
      size="xl"
      scrollable
      centered>
        <!-- Modal content-->
        <div class="row ">
          <div class="col mb-5 ">
            <h4 class="mb-4">{{ $t('registration.choose-group') }}</h4>
            
            <groups-table  @choose="handleGroupClick" :wants-change="wantsChange" :already-registered="alreadyRegistered" :loading="loading" :subject="subject" :groups="groups"></groups-table>
          </div>
        </div>
        <div class="row">
          <div class="col mt-0 pt-3 mt-sm-5 pt-sm-0 mb-3">
            <button type="button" class="btn btn-light-bold" @click="hideModal()">{{ $t('registration.close') }}</button>
          </div>
        </div>
    </b-modal>
    <practise-groups-modal
      @register-practise="choosePractise"
      :subject="subject"
      :seminar="chosenSeminarGroup"
      :group="chosenGroup"
      :wants-change="wantsChange"
      :already-registered="alreadyRegistered"></practise-groups-modal>
    <seminar-groups-modal
      @open-practise-modal="openPractiseModalFromSeminar"
      @register-seminar="chooseSeminar"
      :subject="subject"
      :group="chosenGroup"
      :already-registered="alreadyRegistered"></seminar-groups-modal>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import SkList from '../skeletons/sk-list';
  import GroupsTable from './groups-table';
  import SeminarGroupsModal from './seminar-groups-modal';
  import PractiseGroupsModal from './practise-groups-modal';

  export default {
    name: 'groups-modal',
    components: { PractiseGroupsModal, SeminarGroupsModal, GroupsTable, SkList },

    props: ['subject', 'wantsChange'],

    data() {
      return {
        loading: true,
        groups: [],
        chosenGroup: null,
        chosenSeminarGroup: null,
        chosenPractiseGroup: null,
        old_subjectID:null,
        hasChooseMinor:0,
        preRegID:0
      }
    },

    computed: {
      ...mapGetters({
        alreadyRegistered: 'registration/alreadyRegistered',
        mustChangePreRegSubject:'registration/mustChangePreRegSubject', 
        changedPreRegSubject:'registration/changedPreRegSubject'
       
      }),
    },


    watch:{
      mustChangePreRegSubject(val){

          if(val){

            this.$store.dispatch('registration/getFilteredPreRegSubject',val).then(response=>{

              this.preRegID=response.data[0].preRegID
              
            })

          }


      }



    },

     
    methods: {
      
      getSubjectProgramID(subject) {
        return subject.minor_programID ? subject.minor_programID : subject.programID;
      },

      hideModal() {
        this.$bvModal.hide('groups-modal');
        this.$emit('closed');
        this.chosenGroup = null;
        this.chosenSeminarGroup = null;
        this.chosenPractiseGroup = null;
        this.groups = [];
        location.reload()
        
      },

      onShown() {
        if(!this.alreadyRegistered) {
          this.$store.dispatch('registration/loadAlreadyRegistered')
        }

        this.chosenGroup = null;
        this.chosenSeminarGroup = null;
        this.chosenPractiseGroup = null;

        this.$store.dispatch('registration/getSubjectGroups', {
          subjectID: this.subject.subjectID,
          mustChangePreRegSubject:this.mustChangePreRegSubject
        })
          .then(response => {

           
            this.groups = response.data.data;
            this.loading = false;

            if(this.wantsChange) {
              let groupToChange = this.alreadyRegistered.find(o => o.group == this.wantsChange);
              if(groupToChange.main_group) {
                const mainGroup = this.alreadyRegistered.find(o => o.group == groupToChange.main_group);
                this.openSeminarModal(mainGroup);
              }

              if(this.helpers.practiseLectureIds().indexOf(parseInt(groupToChange.lecture_typeID)) > -1) {
                const mainGroup = this.alreadyRegistered.find(o => o.subjectID == groupToChange.subjectID && !o.main_group);
                this.openPractiseModal(mainGroup);
              }
            }
          })
          .catch(error => {
           // this.hideModal();
            this.helpers.notifyErrorMessage(error);
          })


          this.$store.dispatch('registration/hasChooseMinor').then(response=>{

            this.hasChooseMinor=response.data
            })
             


      },

      
      handleGroupClick(group) {
        if (!parseInt(group.has_practice) && !parseInt(group.has_seminar)) {
          this.$swal({
            title: "",
            text: this.$t('registration.confirm-registration'),
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: this.$t('registration.minor-modal.ok'),
            cancelButtonText: this.$t('registration.minor-modal.cancel'),
            closeOnConfirm: false,
            closeOnCancel: false
          }).then((result) => {
            if(result && result.isConfirmed) this.choose(group);
          });
        }

        if (parseInt(group.has_seminar))
          this.openSeminarModal(group);

        if (!parseInt(group.has_seminar) && parseInt(group.has_practice))
          this.openPractiseModal(group);
      },

      openPractiseModal(group) {
        this.chosenGroup = group;
        this.$bvModal.show('practise-groups-modal');
      },

      openPractiseModalFromSeminar(group) {
        this.chosenSeminarGroup = group;
        this.$bvModal.show('practise-groups-modal');
      },

      openSeminarModal(group) {
        this.chosenGroup = group;
        this.$bvModal.show('seminar-groups-modal');
      },

      choose(group) {
        this.chosenGroup = group;

        if(this.wantsChange !== null) this.changeSubject();
        else this.register();
      },

      register() {
        this.$store.dispatch('registration/register', {
          programModuleID: this.subject.program_moduleID,
          programID: this.getSubjectProgramID(this.subject),
          group: this.chosenGroup,
          old_subjectID:this.mustChangePreRegSubject,
          newSubjectID:this.changedPreRegSubject?this.changedPreRegSubject.subjectID:0,
          hasChooseMinor:this.hasChooseMinor,
          preRegID:this.preRegID
        })
          .then(response => {
            this.helpers.notifySuccessMessage(this.$t('registration.messages.successfully_registered'));
            this.$bvModal.hide('groups-modal');
            this.$emit('registered');
          }).catch(error => {
          this.helpers.notifyErrorMessage(error);
        });
      },

      choosePractise(group) {
        this.chosenPractiseGroup = group;

        if(this.wantsChange !== null) this.changeSubject();
        else this.registerPractise();
      },

      registerPractise() {
        this.$store.dispatch('registration/register', {
          programModuleID: this.subject.program_moduleID,
          programID: this.getSubjectProgramID(this.subject),
          group: this.chosenGroup,
          seminarGroup: this.chosenSeminarGroup,
          practiseGroup: this.chosenPractiseGroup
        })
          .then(response => {
            this.helpers.notifySuccessMessage(this.$t('registration.messages.successfully_registered'));
            this.$bvModal.hide('practise-groups-modal');
            this.$bvModal.hide('seminar-groups-modal');
            this.$bvModal.hide('groups-modal');
            this.$emit('registered');
          }).catch(error => {
          this.helpers.notifyErrorMessage(error);
        });
      },

      chooseSeminar(group) {
        this.chosenSeminarGroup = group;

        if(this.wantsChange !== null) this.changeSubject();
        else this.registerSeminar();
      },

      registerSeminar() {
        this.$store.dispatch('registration/register', {
          programModuleID: this.subject.program_moduleID,
          programID: this.getSubjectProgramID(this.subject),
          group: this.chosenGroup,
          seminarGroup: this.chosenSeminarGroup,
        })
          .then(response => {
            this.helpers.notifySuccessMessage(this.$t('registration.messages.successfully_registered'));
            this.$bvModal.hide('seminar-groups-modal');
            this.$bvModal.hide('groups-modal');
            this.$emit('registered');
          }).catch(error => {
            this.helpers.notifyErrorMessage(error);
          });
      },

      changeSubject() {
        this.$store.dispatch('registration/change', {
          programModuleID: this.subject.program_moduleID,
          programID: this.getSubjectProgramID(this.subject),
          oldGroup: this.wantsChange,
          group: this.chosenGroup,
          seminarGroup: this.chosenSeminarGroup ? this.chosenSeminarGroup : null,
          practiseGroup: this.chosenPractiseGroup ? this.chosenPractiseGroup : null
        })
          .then(response => {
            this.helpers.notifySuccessMessage(this.$t('registration.messages.successfully_changed'));
            this.$bvModal.hide('practise-groups-modal');
            this.$bvModal.hide('seminar-groups-modal');
            this.$bvModal.hide('groups-modal');
            this.$emit('changed');

            this.chosenGroup = null;
            this.chosenSeminarGroup = null;
            this.chosenPractiseGroup = null;
          }).catch(error => {
            this.helpers.notifyErrorMessage(error);
          });
      }
    }
  };
</script>
