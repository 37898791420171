<template>
    <div class="container-fluid">
      <div class="row">
        <sidebar></sidebar>
        <nav-header></nav-header>
  
        <!-- MAIN CONTENT start -->
        <main role="main" class="col-12 col-sm-9 col-md-10 ml-sm-auto col-lg-10" >
        
  
          <section class="px-sm-0 mx-sm-0 px-lg-5 mx-lg-5  mt-0 mt-md-5">
            <div  class="container">
              <div class="row my-5">
                <div class="col-sm-12">
                  
                    <div class="d-flex justify-content-around align-items-center" style="height: 50vh;">
                        <router-link to="/pre-registration" class="btn btn-danger w-25 p-4" style="font-size: 1.5rem;" >{{ $t('registration.firstStep') }}</router-link>
                        <router-link to="/registration" class="btn btn-danger w-25 p-4" style="font-size: 1.5rem;">{{ $t('registration.secondStep') }}</router-link>
                      </div>      

                </div>
              </div>
            </div>
            
    </section>
    </main>
    </div>
    </div>
    </template>


<script>
 import sidebar from '../components/navbar/sidebar';
 
 
  import NavHeader from '../components/navbar/nav-header';
import router from '../router';


export default{

    components:{ sidebar, NavHeader, router }

}

</script>