<template>

    <div>

        <b-modal  
  @shown="onShown()"
 :no-close-on-esc="false"
 :no-close-on-backdrop="false"
  :hide-header="false"
  :hide-footer="true"
  content-class="modal-blue"
  id="write-problem-modal"
  size="xl"
  scrollable
  centered>
    <!-- Modal content-->
   
   
    <div class="row ">
      <div class="col mb-5 ">
             <h3 class="text-center">{{ $t('registration.writeProblem') }}</h3>   



             <textarea v-model="problemText" style="width: 100%; min-height: 400px;" class="form-control"></textarea>
             

      </div>
    </div>






    <div class="row">
      <div class="col mt-0 pt-3 mt-sm-5 pt-sm-0 mb-3">
        <button v-if="showButton" type="button" class="btn btn-light-bold" @click="sendProblem()">{{ $t('registration.sendProblem') }}</button>
      </div>
    </div>
</b-modal>


    </div>



</template>
<script>
export default{

        data(){

            return {

                problemText:null ,
                showButton:true


            }

        },


        methods:{



            sendProblem(){

                if(this.problemText){
                    this.showButton=false

                this.$store.dispatch('registration/writeProblem',this.problemText).then(()=>{

                    this.helpers.notifySuccessMessage(this.$t('registration.problemSent'));
                    this.hideModal()

                })
            }else {
                alert(this.$t('registration.alertEmpty'))
            }
            },

            onShown(){
 

            },
            hideModal() {
                this.$bvModal.hide('write-problem-modal');
                 this.$emit('closed');

            },

          
        }

}

</script>
