<template>
  <div>
    <!-- subject_groups -->
    <a v-b-modal.minor-programs-modal>{{ $t('registration.choose-minor-program') }}</a>

    <b-modal
      @shown="onShown()"
      :hide-header="true"
      :hide-footer="true"
      content-class="modal-blue"
      id="minor-programs-modal"
      size="md">
        <!-- Modal content-->
        <div class="row ">
          <div class="col mb-5 pb-5 ">
            <h4 class="mb-5">{{ $t('registration.choose-minor-program') }}</h4>
            <div class="registration-group-table mb-0">
              <sk-list v-if="loading" height="0.02" :items-count="4"></sk-list>
              <table v-else class="table w-100 table-minor-programs">
                <tr>
                  <th>#</th>
                  <th>{{ $t('registration.program-name') }}</th>
                  <th>&nbsp;</th>
                </tr>
             
                <tr @click="onProgramClick(program)" v-for="(program, i) of minorPrograms">
                  <td>{{ i + 1 }}</td>
                  <td>{{ helpers.getLocalizedField(program, 'name') }}</td>
                  <td>
                    <img v-if="user.minorProgramID === program.programID" src="/static/images/icons/registrations/close.png" class="img-fluid">
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col mt-0 pt-3 mt-sm-5 pt-sm-5 mb-3">
            <button type="button" class="btn btn-light-bold" @click="hideModal()">{{ $t('registration.close') }}</button>
          </div>
        </div>
    </b-modal>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import SkList from '../skeletons/sk-list';

  export default {
    name: 'minor-programs-modal',
    components: { SkList },

    data() {
      return {
        loading: true,
        minorPrograms: [],
        hasChooseMinor:0
      }
    },

    computed: {
      ...mapGetters({
        user: 'auth/user',
      })
    },

    methods: {
      onProgramClick(program) {
        let programID = program.programID;
        if(this.user.minorProgramID === program.programID) programID = null;

        let warningText = '';
        if(this.user.minorProgramID === program.programID) warningText = this.$t('registration.minor-modal.warn-minor-change');
        if(programID === null) warningText = this.$t('registration.minor-modal.warn-minor-delete');

        if(warningText !== '') {
          this.$swal({
            title: "",
            text: warningText,
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: this.$t('registration.minor-modal.ok'),
            cancelButtonText: this.$t('registration.minor-modal.cancel'),
            closeOnConfirm: false,
            closeOnCancel: false
          })
            .then((result) => {
              if (result && result.isConfirmed) {

                  if(programID===null && this.hasChooseMinor==1){

                      if(confirm(this.$t('registration.messages.minorDeleteAlert'))){

                        this.changeMinorProgram(programID);

                      }


                  }else {

                    this.changeMinorProgram(programID);

                  }  

                 
              }
            });
        } else {
          if(programID===null && this.hasChooseMinor==1){

              if(confirm(this.$t('registration.messages.minorDeleteAlert'))){

                this.changeMinorProgram(programID);

              }


              }else {

              this.changeMinorProgram(programID);

              }  
        }
      },

      changeMinorProgram(programID) {
        this.loading = true;
        this.$store.dispatch('registration/chooseMinorPrograms', programID)
          .then((response) => {
            this.$store.dispatch('auth/user', programID);
            programID?this.helpers.notifySuccessMessage(this.$t('registration.messages.minor_program_chosen')):this.helpers.notifySuccessMessage(this.$t('registration.messages.minor_program_deleted'));
            this.$emit('change', programID);
          })
          .catch(error => {
            this.helpers.notifyErrorMessage(error)
          })
        .finally(() => {
          this.onShown();
        });
      },

      hideModal() {
        this.$bvModal.hide('minor-programs-modal');
      },

      onShown() {
    
        this.$store.dispatch('registration/loadMinorPrograms')
        .then((response) => {
          this.loading = false;
          
          this.minorPrograms = response.data.data;
        }) 


        this.$store.dispatch('registration/hasChooseMinor').then(response=>{

          this.hasChooseMinor=response.data
          })
 



      }
    }
  };
</script>

<style scoped>
  .table-minor-programs tr td {
    cursor: pointer;
  }

  .table-minor-programs tr td:first-of-type {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }
  .table-minor-programs tr td:last-of-type {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  .table-minor-programs tr:hover td {
    background-color: rgba(255, 255, 255, 0.3);
  }
</style>
