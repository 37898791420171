import { render, staticRenderFns } from "./must-change-subjects-modal.vue?vue&type=template&id=5162403b&scoped=true"
import script from "./must-change-subjects-modal.vue?vue&type=script&lang=js"
export * from "./must-change-subjects-modal.vue?vue&type=script&lang=js"
import style0 from "./must-change-subjects-modal.vue?vue&type=style&index=0&id=5162403b&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5162403b",
  null
  
)

export default component.exports