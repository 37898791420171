<template>
  <skeleton theme="opacity" shape="radius" bg-color="#dcdbdc" class="p-3 container">
    <div class="row">
      <div :class="'mt-2 ' + widthClass" v-for="i in count">
        <tb-skeleton :aspect-ratio="height"></tb-skeleton>
      </div>
    </div>
  </skeleton>
</template>

<script>
  export default {
    props: ['width-class', 'height', 'count'],
    name: 'sk-cards',
  };
</script>
