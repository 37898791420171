<template>
  <div>
    <b-modal
      @shown="onShown()"
      :hide-header="true"
      :hide-footer="true"
      :no-close-on-esc="true"
      :no-close-on-backdrop="true"
      content-class="modal-blue"
      id="must-change-subjects-modal"
      size="xl"
      centered>
        <!-- Modal content-->
        <div class="row ">
          <div class="col mb-5 pb-5 ">
            <h4 class="mb-5">{{ $t('registration.must-change-subjects') }}</h4>
            <div class="registration-group-table">
              <sk-list v-if="loading" height="0.02" :items-count="4"></sk-list>
              <table v-else class="table table-responsive-lg">
                <thead>
                  <tr>
                    <th>{{ $t('registration.group') }}</th>
                    <th>{{ $t('registration.ects') }}</th>
                    <th>{{ $t('registration.subject') }}</th>
                    <th>{{ $t('registration.lecturer') }}</th>
                    <th>{{ $t('registration.type') }}</th>
                    <th>{{ $t('registration.day') }}</th>
                    <th class="min-width: 100px;">{{ $t('registration.date') }}</th>
                    <th style="min-width: 100px">{{ $t('registration.time') }}</th>
                    <th>{{ $t('registration.room') }}</th>
                    <th style="width: 36px">&nbsp;</th>
                    <th style="width: 36px">&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  <tr :class="[{ 'text-red': mustChangeGroups.indexOf(group.group) !== -1 }, { 'border-bottom': !sameAsNextGroup(i) }]" v-for="(group, i) of alreadyRegistered">
                    <td>{{ group.group }}</td>
                    <td>{{ group.ECTS }}</td>
                    <td>{{ helpers.getLocalizedField(group, 'subject') }}</td>
                    <td>{{ helpers.getLocalizedField(group, 'lectors') }}</td>
                    <td>{{ helpers.getLocalizedField(group, 'type') }}</td>
                    <td>{{ helpers.getLocalizedField(group, 'day') }}</td>
                    <td>
                      <span v-if="group.start_date && group.start_date !== ''">{{ group.start_date }} <br /> {{ group.end_date }}</span>
                      <span v-else>{{ group.holddate }}</span>
                    </td>
                    <td>{{ group.time + ' - ' + group.time_end }}</td>
                    <td v-if="group.start_date && group.start_date !== ''">
                      {{ $t('registration.curation') }}, {{ helpers.getLocalizedField(group, 'Rooms') }}
                    </td>
                    <td v-else-if="group.Rooms">{{ helpers.getLocalizedField(group, 'Rooms') }}</td>
                    <td v-else>{{ $t('registration.distance-room') }}</td>
                    <td style="min-width:4rem;">
                      <img class="img-fluid"
                          @click="changeGroup(group)"
                          src="/static/images/icons/registrations/change.png">
                    </td>
                    <td style="min-width:4rem;">
                      <img class="img-fluid"
                          @click="removeGroup(group)"
                          src="/static/images/icons/registrations/close.png">
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="px-5 px-sm-0 mx-5 mx-sm-0">
              <sk-list v-if="loading" height="0.01" :items-count="1"></sk-list>
              <p v-else class="warninng">{{ $t('registration.total-ects') }}: {{ ectsCurrentSemester }}</p>
            </div>
          </div>
        </div>
    </b-modal>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import SkList from '../skeletons/sk-list';

  export default {
    name: 'must-change-subjects-modal',
    components: { SkList },
    computed: {
      totalEcts() {
        return this.alreadyRegistered.reduce(function(a, b){
          return a + parseInt(b.ECTS);
        }, 0);
      },
      ...mapGetters({
        alreadyRegistered: 'registration/alreadyRegistered',
        ectsCurrentSemester: 'registration/ectsCurrentSemester',
        mustChangeGroups: 'registration/mustChangeGroups',
      })
    },

    data() {
      return {
        loading: true
      }
    },

    methods: {
      changeGroup(group) {
        this.$emit('subject-change', group);
      },

      frGroup(group) {
        this.$emit('subject-change', group);
      },

      removeGroup(group) {
        this.$store.dispatch('registration/removeSubject', group.subjectID)
        .then((response) => {
          this.helpers.notifySuccessMessage(this.$t('registration.messages.successfully_removed'));
          this.onShown();
          this.$emit('subject-remove');
        })
      },

      sameAsNextGroup(index) {
        return !this.alreadyRegistered[index + 1] || this.alreadyRegistered[index].subject === this.alreadyRegistered[index + 1].subject;
      },

      onShown() {
        this.$store.dispatch('registration/loadAlreadyRegistered')
        .then(() => {
          this.loading = false
        });
      }
    }
  };
</script>

<style scoped>
  .registration-group-table img {
      cursor: pointer;
  }

  tr.text-red td {
    color: #f00 !important;
  }
</style>
